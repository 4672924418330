import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import { AdminContext } from '../contexts/admin.context';
import { CreateProjectSchema } from '../schema/project.schema';
import { createProject } from '../service';
import { getUserId } from '../utils/user';

import Button from '../components/common/Button';
import AdminInput from '../components/common/AdminInput';
import NumberInput from '../components/common/NumberInput';
import { ThumbsUpIcon } from '../components/icons';
import { trackEvent } from '../utils';
import { getTranslation } from '../utils/translation';
import InputSelect from '../components/common/InputSelect';

const ClassProject = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [creatingClass, setCreatingClass] = useState(false);

  const {
    setPopupInfo,
    themeId,
    teamType,
    user,
    portalConfig: { productId, projectTypes, languages = [] } = {}
  } = useContext(AdminContext);

  const [languageOptions] = useState([
    { key: '', title: '' },
    ...languages?.map((l) => ({ value: l, label: l.toUpperCase() }))
  ]);

  const basePath = `/${themeId}/${teamType}`;

  const handleSubmit = async (formValues) => {
    const userAccountId = getUserId(user, themeId, teamType, 'account');
    const userId = getUserId(user, themeId, teamType);

    if (!formValues?.projectType) formValues.projectType = projectTypes?.find((p) => p.enabled)?.id;

    const projectValues = {
      accountId: userAccountId,
      productId: productId,
      noOfParticipants: formValues.noOfParticipants,
      specs: {
        moderator: userId,
        language: formValues.language,
        notTanProtected: false,
        description: formValues.classCodeName,
        projectType: formValues.projectType,
        teamType: teamType?.toLowerCase(),
        teacherRegion: user?.address?.[0]?.street
      }
    };

    try {
      setCreatingClass(true);
      const response = await createProject(projectValues);
      // show succcess popup
      if (response.status === 200) {
        setPopupInfo({
          isVisible: true,
          icon: ThumbsUpIcon,
          heading: getTranslation(t, 'createProject.yourProjectCreated'),
          action: {
            head: getTranslation(t, 'createProject.manageYourHead'),
            text: getTranslation(t, 'createProject.manageYourProject'),
            type: 'normal',
            onClick: () => {
              navigate(basePath + '/projects');
              setPopupInfo({ isVisible: false });
            }
          }
        });
        return;
      }
      toast.error(getTranslation(t, 'createProject.errors.failedCreateProject'));
    } catch (e) {
      toast.error(getTranslation(t, 'createProject.errors.failedCreateProject'));
      console.error('Filed to create a new project', e);
    } finally {
      formik.resetForm();
      setCreatingClass(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      noOfParticipants: 1,
      classCodeName: '',
      projectType: projectTypes?.[0]?.id,
      language: ''
    },
    validationSchema: CreateProjectSchema,
    onSubmit: handleSubmit
  });

  useEffect(() => {
    trackEvent('page_view', {
      organization: themeId === 'ch' ? 'SSK' : 'WSF'
    });
  }, []);

  useEffect(() => {
    // Auto-select the only language if there's just one option
    if (languageOptions?.length < 2) {
      formik.setFieldValue('language', 'fr');
    } else if (languageOptions?.length < 3) {
      // length is 2 because we have an empty option + 1 language
      formik.setFieldValue('language', languageOptions[1].value);
    }
  }, [languageOptions]);

  return (
    <div className="overflow-y-auto w-full mini-scrollbar pr-4">
      <div className="mb-14">
        <h1
          className="text-2xl font-bold text-left text-cblack-primary mb-2.5"
          dangerouslySetInnerHTML={{ __html: getTranslation(t, 'createProject.listHead') }}
        />
        <div
          className="text=lg text-cblack-primary"
          dangerouslySetInnerHTML={{ __html: getTranslation(t, 'createProject.point1') }}
        />
      </div>

      <div className="mt-5 max-w-xl px-1">
        <form onSubmit={formik.handleSubmit}>
          <InputSelect
            label={getTranslation(t, 'createProject.form.projectType')}
            value={formik.values.projectType}
            options={projectTypes?.map(({ id, title, enabled }) => ({
              value: id,
              label: title?.[i18n.language] || title?.en || title?.de || title?.fr || title?.it,
              disabled: !enabled
            }))}
            formik={formik}
            onChange={(value) => {
              formik.setFieldValue('projectType', value);
            }}
            name="projectType"
          />
          <NumberInput
            label={getTranslation(t, 'createProject.form.noOfParticipantsLabel')}
            subtext={getTranslation(t, 'createProject.form.noOfParticipantsSubtext')}
            isDark={true}
            name="noOfParticipants"
            min="0"
            formik={formik}
          />
          {languageOptions?.length > 2 && (
            <InputSelect
              label={getTranslation(t, 'createProject.form.languageLabel')}
              value={formik.values.language}
              options={languageOptions}
              formik={formik}
              onChange={(value) => {
                formik.setFieldValue('language', value);
              }}
              name="language"
              error={formik.errors.language}
              touched={formik.touched.language}
            />
          )}
          <AdminInput
            label={getTranslation(t, 'createProject.form.classCodeNameLabel')}
            subtext={getTranslation(t, 'createProject.form.classCodeNameSubtext')}
            isDark={true}
            placeholder={getTranslation(t, 'createProject.form.classCodePlaceholder')}
            type="text"
            name="classCodeName"
            formik={formik}
          />
          <Button buttonType="login" type="submit" loading={creatingClass} disabled={creatingClass}>
            {getTranslation(t, 'createProject.actionCreate')}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ClassProject;

import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import OtpInput from 'react18-input-otp';

import { AdminContext } from '../contexts/admin.context';
import { trackEvent } from '../utils';
import { getTranslation } from '../utils/translation';
import { getAllProjects, getTanDetailsPublic, transferStudent } from '../service';
import FullScreenLoader from '../components/common/fullScreenLoader';
import Button from '../components/common/Button';
import { toast } from 'react-toastify';
import ConfirmationPopup from '../components/common/ConfirmationPopup';

const TransferStudent = () => {
  const { t } = useTranslation();
  const { themeId, teamType, themeConfig: { buttonRadius } = {} } = useContext(AdminContext);
  const { aid } = useParams();

  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [tan, setTan] = useState('');
  const [loading, setLoading] = useState(true);
  const [transferLoading, setTransferLoading] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await getAllProjects(themeId, teamType, aid);
        setProjects(data);
        setSelectedProject(data[0]);
      } catch (error) {
        console.error('Error fetching projects', error);
      } finally {
        setLoading(false);
      }
    })();
  }, [aid]);

  const submitDecision = async () => {
    try {
      setTransferLoading(true);

      const { data: { account_id: oldaid, project_id: oldpid } = {} } = await getTanDetailsPublic(
        themeId,
        teamType,
        tan
      );

      await transferStudent(themeId, teamType, {
        tan,
        oldpid,
        oldaid,
        newpid: selectedProject?.projectId,
        newaid: selectedProject?.accountId
      });

      setTan('');

      toast.success(getTranslation(t, 'transferStudent.success'));
    } catch (error) {
      console.error('Error submitting decision', error);
      toast.error(getTranslation(t, 'transferStudent.error'));
    } finally {
      setTransferLoading(false);
    }
  };

  useEffect(() => {
    trackEvent('page_view', {
      organization: themeId === 'ch' ? 'SSK' : 'WSF'
    });
  }, []);

  if (loading) return <FullScreenLoader />;

  return (
    <>
      <div className="flex flex-col items-center justify-center gap-6 h-calc-vh overflow-y-auto px-2">
        {showConfirmationPopup && (
          <ConfirmationPopup
            heading={getTranslation(t, 'transferStudent.popup.heading')}
            description={getTranslation(t, 'transferStudent.popup.description')}
            action={{
              icon: null,
              text: getTranslation(t, 'transferStudent.popup.continue'),
              onClick: () => submitDecision()
            }}
            cancelButton={{
              text: getTranslation(t, 'transferStudent.popup.cancel'),
              onClick: () => setShowConfirmationPopup(false)
            }}
            onHide={() => setShowConfirmationPopup(false)}
          />
        )}
        <div className="flex flex-col items-center justify-center gap-6 w-full max-w-lg">
          <h1
            className="text-3xl font-bold text-cblack-primary text-left w-full"
            dangerouslySetInnerHTML={{ __html: getTranslation(t, 'transferStudent.heading') }}
          />
          <select
            className={`px-4 py-3.5 rounded w-full text-gray-primary text-lg bg-gray-100`}
            style={{ borderRadius: buttonRadius }}
            id="project"
            name="project"
            value={selectedProject?.projectId}
            onChange={(e) => {
              const targetProject = projects.find(
                (project) => project.projectId === e.target.value
              );
              setSelectedProject(targetProject);
            }}>
            {projects.map((project, index) => (
              <option key={index} value={project.projectId}>
                {`https://www.mission-future.com/${project?.accountId}/${project?.projectId}`}
              </option>
            ))}
          </select>
          <h2
            className="text-3xl font-bold text-cblack-primary text-left w-full"
            dangerouslySetInnerHTML={{ __html: getTranslation(t, 'transferStudent.tan.heading') }}
          />
          <p
            className="text-xl text-gray-primary text-left w-full "
            dangerouslySetInnerHTML={{
              __html: getTranslation(t, 'transferStudent.tan.subHeading')
            }}
          />

          <OtpInput
            containerStyle={{
              width: '100%',
              display: 'grid',
              gridTemplateColumns: 'repeat(6, 1fr)',
              gap: '10px'
            }}
            inputStyle={{
              height: '100%',
              width: '100%',
              fontSize: '2.25rem',
              padding: '0.5rem 0.75rem',
              borderRadius: '0.5rem',
              border: '2px solid #000000',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
            }}
            focusStyle={{
              height: '100%',
              width: '100%',
              fontSize: '2.25rem',
              padding: '0.5rem 0.75rem',
              borderRadius: '0.5rem',
              border: '2px solid #000000',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
            }}
            isInputNum={false}
            shouldAutoFocus
            numInputs={6}
            value={tan}
            onChange={setTan}
          />
        </div>

        <div className="mt-8 flex w-full flex-col gap-4 items-center justify-center">
          {/* <Button
            buttonType={'primary'}
            extraClass="w-fit"
            onClick={() => submitDecision(false)}
            loading={transferLoading}>
            <span
              dangerouslySetInnerHTML={{ __html: getTranslation(t, 'transferStudent.submitStay') }}
            />
          </Button> */}
          <Button
            buttonType={'outline'}
            extraClass="w-fit"
            onClick={() => setShowConfirmationPopup(true)}
            loading={transferLoading}>
            <span
              dangerouslySetInnerHTML={{ __html: getTranslation(t, 'transferStudent.submitLeave') }}
            />
          </Button>
        </div>
      </div>
    </>
  );
};

export default TransferStudent;

import * as yup from 'yup';

export const CreateProjectSchema = yup.object().shape({
  noOfParticipants: yup.number().min(1, 'CreateProjectMin').required('noOfParticipants'),
  classCodeName: yup.string().required('classCodeName'),
  projectType: yup.string(),
  language: yup.string().required('language')
});

export const AddParticipantsSchema = yup.object().shape({
  noOfParticipants: yup.number().min(1, 'CreateProjectMin').required('noOfParticipants')
});

export const ConvertProfileSchema = yup
  .object({
    field1: yup.string(),
    field2: yup.string(),
    field3: yup.string(),
    field4: yup.string(),
    field5: yup.string(),
    field6: yup.string()
  })
  .test('access-code', 'Enter all values', function (value) {
    if (
      !value.field1 ||
      !value.field2 ||
      !value.field3 ||
      !value.field4 ||
      !value.field5 ||
      !value.field6
    )
      return true;
  });

import { useContext } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { AdminContext } from '../../contexts/admin.context';
import { getTranslation } from '../../utils/translation';

export default function InputSelect({
  name,
  value,
  placeholder,
  className,
  containerClassName,
  label,
  error,
  touched,
  options,
  onChange,
  focus = true,
  isDark = false
}) {
  const { t } = useTranslation();

  const {
    themeConfig: { buttonRadius }
  } = useContext(AdminContext);

  return (
    <div className={`mb-4 ${containerClassName}`}>
      {label && (
        <label
          className="block mb-1 text-base font-bold text-cblack-primary"
          dangerouslySetInnerHTML={{ __html: label }}
        />
      )}
      <select
        className={clsx(
          `px-4 py-3.5 w-full outline-none text-gray-primary text-base bg-gray-100 rounded-md ${className}`,
          {
            'bg-input': isDark
          },
          {
            'focus:ring-1': focus
          },
          {
            'focus:ring-black': focus
          },
          { 'bg-red-100': error }
        )}
        style={{ borderRadius: buttonRadius }}
        id={name}
        name={name}
        value={value}
        onChange={(e) => onChange(e.target.value)}>
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {options?.length > 0 &&
          options?.map((option) => (
            <option key={option.value} value={option.value} disabled={option.disabled}>
              {option.label}
            </option>
          ))}
      </select>
      {touched && error && (
        <p className="ml-1 text-sm text-red-600">{getTranslation(t, `error.${error}`)}</p>
      )}
    </div>
  );
}
